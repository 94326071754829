<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 타입
            </p>
            <b-form-radio-group
              v-model="query.viewType"
            >
              <b-form-radio value="summary">
                요약
              </b-form-radio>
              <b-form-radio value="detail">
                상세
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query.filterStr"
              placeholder="제목을 입력하세요"
              class="el-def"
              style="width: 350px"
              @keyup.enter="fetchData()"
            />
            <v-select
              v-model="query.status"
              :options="codes.surveyStatus"
              placeholder="설문상태(전체)"
              :reduce="option => option.code"
              class="el-def"
              style="width: 200px"
            />
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="조회기간(Fr)"
              style="width: 240px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="조회기간(To)"
              style="width: 240px"
            />
            <b-form-input
              v-model="query.liveInfoId"
              class="el-def"
              placeholder="ID"
              style="width: 140px;"
            />
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <summary-grid
        v-if="viewType === 'summary'"
        :data="surveys"
      />

      <detail-grid
        v-else-if="viewType === 'detail'"
        :data="surveys"
      />
    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import { dateFormatter } from '@core/utils/filter'
import SummaryGrid from './grid/SummaryGrid.vue'
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    vSelect,
    SummaryGrid,
    DetailGrid,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
      // fetchData()  // ID 필수 검색으로 자동조회 off
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Set Codes
    const codes = ref({
      surveyStatus: [],
    })

    const fetchCodes = () => {
      axios.get('/fa/statistics/survey/codes')
        .then(rs => {
          const {
            surveyLiveStatus,
          } = rs.data
          const surveyStatus = surveyLiveStatus.filter(x => x.code > 1)
          codes.value.surveyStatus = surveyStatus
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    // Set Codes End.

    const query = ref({
      viewType: 'summary',
      startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      status: null,
      filterStr: null,
      liveInfoId: null,
    })

    const viewType = ref("summary")
    const headers = ref([])
    const surveys = ref([])

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)

      if (!query.value.liveInfoId?.trim()) {
        pushToast('warning', 'ID는 필수 검색조건입니다.')
        return
      }

      if (Math.abs(frDate.diff(toDate, 'day')) > 90) {
        pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
        return
      }

      axios.get(`/fa/statistics/survey/${query.value.viewType}`, {
        params: {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          status: query.value.status,
          filterStr: query.value.filterStr,
          liveInfoId: query.value.liveInfoId,
        },
      })
        .then(rs => {
          viewType.value = query.value.viewType

          surveys.value = rs.data || []
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      if (viewType.value === 'summary') summaryDownload()
      else if (viewType.value === 'detail') detailDownload()
    }

    const summaryDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 15 },
        { header: "상태", key: "isFinish", width: 15 },
        { header: "참여기준", key: "allowJoinTypeName", width: 15 },
        { header: "제약사", key: "clientName", width: 25 },
        { header: "제목", key: "title", width: 50 },
        { header: "게시기간", key: "term", width: 25 },
        {
          header: "목표인원", key: "targetPeopleNum", width: 10, style: { numFmt: '#,##0' },
        },
        { header: "진료과", key: "medicalDept", width: 20 },
        {
          header: "총 클릭 수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "총 클릭 수 (중복제거)", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "총 참여자 수", key: "submitCount", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      const startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      surveys.value.map(e => {
        e.departments.map(x => worksheet.addRow({
          id: e.survey.liveInfoId,
          isFinish: e.survey.isFinish ? "종료" : "진행중",
          allowJoinTypeName: e.survey.allowJoinTypeName,
          clientName: e.survey.clientName,
          title: e.survey.title,
          term: `${dateFormatter(e.survey.startDate)} ~ ${dateFormatter(e.survey.endDate)}`,
          targetPeopleNum: e.survey.targetPeopleNum,
          medicalDept: x.medicalDepartmentName,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          submitCount: x.submitCount,
        }))

        if (e.departments.length > 0) {
          worksheet.addRow({
            id: e.survey.liveInfoId,
            isFinish: e.survey.isFinish ? "종료" : "진행중",
            allowJoinTypeName: e.survey.allowJoinTypeName,
            clientName: e.survey.clientName,
            title: e.survey.title,
            term: `${dateFormatter(e.survey.startDate)}~${dateFormatter(e.survey.endDate)}`,
            targetPeopleNum: e.survey.targetPeopleNum,
            medicalDept: '소계',
            totalClickCount: e.departments.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.departments.reduce((f, l) => f + l.groupClickCount, 0),
            submitCount: e.departments.reduce((f, l) => f + l.submitCount, 0),
          })

          worksheet.mergeCells(`A${endRow}:A${endRow + e.departments.length}`)
          worksheet.mergeCells(`B${endRow}:B${endRow + e.departments.length}`)
          worksheet.mergeCells(`C${endRow}:C${endRow + e.departments.length}`)
          worksheet.mergeCells(`D${endRow}:D${endRow + e.departments.length}`)
          worksheet.mergeCells(`E${endRow}:E${endRow + e.departments.length}`)
          worksheet.mergeCells(`F${endRow}:F${endRow + e.departments.length}`)
          worksheet.mergeCells(`G${endRow}:G${endRow + e.departments.length}`)

          endRow += e.departments.length
          endRow += 1
        }
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "설문조사 통계(요약).xlsx")
    }

    const detailDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '상태'
      worksheet.getCell('C1').value = '참여기준'
      worksheet.getCell('D1').value = '제약사'
      worksheet.getCell('E1').value = '제목'
      worksheet.getCell('F1').value = '게시기간'
      worksheet.getCell('G1').value = '목표인원'
      worksheet.getCell('H1').value = '회원구분'
      worksheet.getCell('I1').value = '진료과'

      worksheet.getCell('J1').value = '모아보기'
      worksheet.mergeCells(`J1:L1`)

      worksheet.getCell('M1').value = '처방팝업'
      worksheet.mergeCells(`M1:S1`)

      worksheet.getCell('T1').value = '스케줄팝업'
      worksheet.mergeCells(`T1:X1`)

      worksheet.getCell('Y1').value = '닥터인포'
      worksheet.mergeCells(`Y1:AA1`)

      worksheet.getCell('AB1').value = '총 클릭 수'
      worksheet.getCell('AC1').value = '총 클릭 수 (중복제거)'
      worksheet.getCell('AD1').value = '총 참여 수'

      worksheet.getCell('J2').value = '클릭 수'
      worksheet.getCell('K2').value = '클릭 수 (중복제거)'
      worksheet.getCell('L2').value = '참여자 수'

      worksheet.getCell('M2').value = '새처방'
      worksheet.getCell('N2').value = '재처방'
      worksheet.getCell('O2').value = '노출 수'
      worksheet.getCell('P2').value = '노출 수 (중복제거)'
      worksheet.getCell('Q2').value = '클릭 수'
      worksheet.getCell('R2').value = '클릭 수 (중복제거)'
      worksheet.getCell('S2').value = '참여자 수'

      worksheet.getCell('T2').value = '노출 수'
      worksheet.getCell('U2').value = '노출 수 (중복제거)'
      worksheet.getCell('V2').value = '클릭 수'
      worksheet.getCell('W2').value = '클릭 수 (중복제거)'
      worksheet.getCell('X2').value = '참여자 수'

      worksheet.getCell('Y2').value = '클릭 수'
      worksheet.getCell('Z2').value = '클릭 수 (중복제거)'
      worksheet.getCell('AA2').value = '참여자 수'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`G1:G2`)
      worksheet.mergeCells(`H1:H2`)
      worksheet.mergeCells(`I1:I2`)
      worksheet.mergeCells(`AB1:AB2`)
      worksheet.mergeCells(`AC1:AC2`)
      worksheet.mergeCells(`AD1:AD2`)

      worksheet.columns = [
        { key: "id", width: 15 },
        { key: "isFinish", width: 15 },
        { key: "allowJoinTypeName", width: 15 },
        { key: "clientName", width: 25 },
        { key: "title", width: 50 },
        { header: "게시기간", key: "term", width: 25 },
        {
          header: "목표인원", key: "targetPeopleNum", width: 10, style: { numFmt: '#,##0' },
        },
        { key: "memberType", width: 10 },
        { key: "medicalDept", width: 20 },
        { key: "totalMoaClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupMoaClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "submitMoaCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "prescriptionNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "prescriptionReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPrescriptionOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPrescriptionClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "submitPrescriptionCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupScheduleOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupScheduleClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "submitScheduleCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "submitDrInfoCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalSubmitCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      surveys.value.map(e => {
        e.memberDepts.map(x => worksheet.addRow({
          id: e.liveInfoId,
          isFinish: e.isFinish ? "종료" : "진행중",
          allowJoinTypeName: e.allowJoinTypeName,
          clientName: e.clientName,
          title: e.title,
          term: `${dateFormatter(e.startDate)} ~ ${dateFormatter(e.endDate)}`,
          targetPeopleNum: e.targetPeopleNum,
          memberType: '회원',
          medicalDept: x.medicalDeptName,
          totalMoaClickCount: x.totalMoaClickCount,
          groupMoaClickCount: x.groupMoaClickCount,
          submitMoaCount: x.submitMoaCount,
          prescriptionNewCount: x.prescriptionNewCount,
          prescriptionReCount: x.prescriptionReCount,
          totalPrescriptionOpenCount: x.totalPrescriptionOpenCount,
          groupPrescriptionOpenCount: x.groupPrescriptionOpenCount,
          totalPrescriptionClickCount: x.totalPrescriptionClickCount,
          groupPrescriptionClickCount: x.groupPrescriptionClickCount,
          submitPrescriptionCount: x.submitPrescriptionCount,
          totalScheduleOpenCount: x.totalScheduleOpenCount,
          groupScheduleOpenCount: x.groupScheduleOpenCount,
          totalScheduleClickCount: x.totalScheduleClickCount,
          groupScheduleClickCount: x.groupScheduleClickCount,
          submitScheduleCount: x.submitScheduleCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          submitDrInfoCount: x.submitDrInfoCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          totalSubmitCount: x.totalSubmitCount,
        }))

        if (e.memberDepts.length > 0) {
          worksheet.addRow({
            id: e.liveInfoId,
            isFinish: e.isFinish ? "종료" : "진행중",
            allowJoinTypeName: e.allowJoinTypeName,
            clientName: e.clientName,
            title: e.title,
            term: `${dateFormatter(e.startDate)} ~ ${dateFormatter(e.endDate)}`,
            targetPeopleNum: e.targetPeopleNum,
            memberType: '소계',
            medicalDept: '',
            totalMoaClickCount: e.memberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.memberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            submitMoaCount: e.memberDepts.reduce((f, l) => f + l.submitMoaCount, 0),
            prescriptionNewCount: e.memberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.memberDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.memberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.memberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.memberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.memberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            submitPrescriptionCount: e.memberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0),
            totalScheduleOpenCount: e.memberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.memberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.memberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.memberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            submitScheduleCount: e.memberDepts.reduce((f, l) => f + l.submitScheduleCount, 0),
            totalDrInfoClickCount: e.memberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            submitDrInfoCount: e.memberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0),
            totalClickCount: e.memberDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.memberDepts.reduce((f, l) => f + l.groupClickCount, 0),
            totalSubmitCount: e.memberDepts.reduce((f, l) => f + l.totalSubmitCount, 0),
          })

          worksheet.mergeCells(`H${endRow}:H${endRow + e.memberDepts.length - 1}`)
          endRow += e.memberDepts.length

          worksheet.mergeCells(`H${endRow}:I${endRow}`)

          if (e.nonMemberDepts.length > 0) endRow += 1
        }

        e.nonMemberDepts.map(x => worksheet.addRow({
          id: e.liveInfoId,
          isFinish: e.isFinish ? "종료" : "진행중",
          allowJoinTypeName: e.allowJoinTypeName,
          clientName: e.clientName,
          title: e.title,
          term: `${dateFormatter(e.startDate)} ~ ${dateFormatter(e.endDate)}`,
          targetPeopleNum: e.targetPeopleNum,
          memberType: '비회원',
          medicalDept: x.medicalDeptName,
          totalMoaClickCount: x.totalMoaClickCount,
          groupMoaClickCount: x.groupMoaClickCount,
          submitMoaCount: x.submitMoaCount,
          prescriptionNewCount: x.prescriptionNewCount,
          prescriptionReCount: x.prescriptionReCount,
          totalPrescriptionOpenCount: x.totalPrescriptionOpenCount,
          groupPrescriptionOpenCount: x.groupPrescriptionOpenCount,
          totalPrescriptionClickCount: x.totalPrescriptionClickCount,
          groupPrescriptionClickCount: x.groupPrescriptionClickCount,
          submitPrescriptionCount: x.submitPrescriptionCount,
          totalScheduleOpenCount: x.totalScheduleOpenCount,
          groupScheduleOpenCount: x.groupScheduleOpenCount,
          totalScheduleClickCount: x.totalScheduleClickCount,
          groupScheduleClickCount: x.groupScheduleClickCount,
          submitScheduleCount: x.submitScheduleCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          submitDrInfoCount: x.submitDrInfoCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          totalSubmitCount: x.totalSubmitCount,
        }))

        if (e.nonMemberDepts.length > 0) {
          worksheet.addRow({
            id: e.liveInfoId,
            isFinish: e.isFinish ? "종료" : "진행중",
            allowJoinTypeName: e.allowJoinTypeName,
            clientName: e.clientName,
            title: e.title,
            term: `${dateFormatter(e.startDate)} ~ ${dateFormatter(e.endDate)}`,
            targetPeopleNum: e.targetPeopleNum,
            memberType: '소계',
            medicalDept: '',
            totalMoaClickCount: e.nonMemberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.nonMemberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            submitMoaCount: e.nonMemberDepts.reduce((f, l) => f + l.submitMoaCount, 0),
            prescriptionNewCount: e.nonMemberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.nonMemberDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            submitPrescriptionCount: e.nonMemberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0),
            totalScheduleOpenCount: e.nonMemberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.nonMemberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.nonMemberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.nonMemberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            submitScheduleCount: e.nonMemberDepts.reduce((f, l) => f + l.submitScheduleCount, 0),
            totalDrInfoClickCount: e.nonMemberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.nonMemberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            submitDrInfoCount: e.nonMemberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0),
            totalClickCount: e.nonMemberDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.nonMemberDepts.reduce((f, l) => f + l.groupClickCount, 0),
            totalSubmitCount: e.nonMemberDepts.reduce((f, l) => f + l.totalSubmitCount, 0),
          })

          worksheet.mergeCells(`H${endRow}:H${endRow + e.nonMemberDepts.length - 1}`)
          endRow += e.nonMemberDepts.length

          worksheet.mergeCells(`H${endRow}:I${endRow}`)
        }

        if (e.memberDepts.length > 0 || e.nonMemberDepts.length > 0) {
          worksheet.addRow({
            id: e.liveInfoId,
            isFinish: e.isFinish ? "종료" : "진행중",
            allowJoinTypeName: e.allowJoinTypeName,
            clientName: e.clientName,
            title: e.title,
            term: `${dateFormatter(e.startDate)} ~ ${dateFormatter(e.endDate)}`,
            targetPeopleNum: e.targetPeopleNum,
            memberType: '합계',
            medicalDept: '',
            totalMoaClickCount: e.memberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.memberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            submitMoaCount: e.memberDepts.reduce((f, l) => f + l.submitMoaCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.submitMoaCount, 0),
            prescriptionNewCount: e.memberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.memberDepts.reduce((f, l) => f + l.prescriptionReCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.memberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.memberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.memberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.memberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            submitPrescriptionCount: e.memberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.submitPrescriptionCount, 0),
            totalScheduleOpenCount: e.memberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.memberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.memberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.memberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            submitScheduleCount: e.memberDepts.reduce((f, l) => f + l.submitScheduleCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.submitScheduleCount, 0),
            totalDrInfoClickCount: e.memberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            submitDrInfoCount: e.memberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.submitDrInfoCount, 0),
            totalClickCount: e.memberDepts.reduce((f, l) => f + l.totalClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.memberDepts.reduce((f, l) => f + l.groupClickCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.groupClickCount, 0),
            totalSubmitCount: e.memberDepts.reduce((f, l) => f + l.totalSubmitCount, 0) + e.nonMemberDepts.reduce((f, l) => f + l.totalSubmitCount, 0),
          })

          endRow += 1
          worksheet.mergeCells(`H${endRow}:I${endRow}`)
        }

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)
        worksheet.mergeCells(`E${startRow}:E${endRow}`)
        worksheet.mergeCells(`F${startRow}:F${endRow}`)
        worksheet.mergeCells(`G${startRow}:G${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "설문조사 통계(상세).xlsx")
    }

    return {
      codes,
      query,
      viewType,
      fetchData,
      headers,
      surveys,
      excelDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
